<template>
  <b-row>
    <div v-if="false" class="watermark"></div>
    <b-col cols="12" sm="12" md="12" lg="12" xl="4">

      <b-row class="justify-content-md-center" align-h="center">
        <b-col cols="6" sm="4" md="4" lg="4" xl="5" class="mt-2 text-center">
          <b-avatar rounded :src="avatar" variant="light" badge badge-offset="-1.5em" badge-variant="white" class="w-third float-image">
            <template #badge>
              <i :class="`${utils.getClassNetworkSpan('instagram')} margin-icon font-30`"/>
            </template>
          </b-avatar>
          <b-img class="show-image-sm" :src="require('@/assets/images/logo/logo-brandme1.png')"/>
        </b-col>
      </b-row>

      <b-row class="mt-10 text-center">
        <b-col cols="12">
          <h1 class="summary-title">
            {{data.username}}
            <image-verified v-if="data.is_verified" style="width: 25px;height: 25px;"/>
          </h1>
        </b-col>
        <b-col cols="6" class="mt-2">
          <b-card no-body class="no-shadow m-0">
            <b-card-header>
              <div class="text-center w-full">
                <h4>{{$t('instagram.followersTooltip')}}</h4>
                <b-card-title><h2 class="mb-0 font-weight-bolder">{{ followersCount }}</h2></b-card-title>
              </div>
            </b-card-header>
          </b-card>
        </b-col>

        <b-col cols="6" class="mt-2">
          <b-card no-body class="no-shadow m-0">
            <b-card-header>
              <div class="text-center w-full">
                <h4>{{$t('profile.er')}}</h4>
                <b-card-title><h2 class="mb-0 font-weight-bolder">{{data.er.value > 0 ? utils.decimalFixed(data.er.value, 2) : utils.decimalFixed(data.er.avg, 2)}} %</h2></b-card-title>
              </div>
            </b-card-header>
          </b-card>
        </b-col>
      </b-row>


       <b-row class="text-center mx-0 " v-if="percentage_male && percentage_female">
        <b-col cols="6" class="d-flex align-items-between flex-column py-1">
          <h4 class="mb-0">
            {{$t('instagram.female')}}
          </h4>
          <h2 class="font-weight-bolder mb-50 color-female">
            {{ percentage_female }}%
          </h2>
        </b-col>
        <b-col cols="6" class="d-flex align-items-between flex-column py-1">
          <h4 class="mb-0">
            {{$t('instagram.male')}}
          </h4>
          <h2 class="font-weight-bolder mb-50 color-male">
            {{ percentage_male }}%
          </h2>
        </b-col>
      </b-row>

      <b-row class="avg-sessions p-1">
        <b-col cols="12" class="mb-2 text-center">
          <h4 class="mb-50">{{$t("instagram.qualityAudience")}}: <h2 class="mb-0 font-weight-bolder">{{aqsFormated}}</h2></h4>
          <b-progress :value="aqs" max="100" height="6px" :variant="getColor(aqs)"></b-progress>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" v-if="reach_from + reach_to > 0">
          <div class="d-flex mt-1 align-items-center">
            <h4 class="custom-p-modal">
              {{ $t('profile.estimate_reach') }}
              <b-icon
                font-scale="1"
                icon="question-circle"
                class="icon-tooltip"
                id="estimate-reach-modal"
              ></b-icon>
              <b-tooltip target="estimate-reach-modal" triggers="hover" variant="primary">
                {{$t('profile.estimatedScopeInfo')}}
              </b-tooltip>
            </h4>
            <h2 class="ml-2"><strong>{{utils.getFormat(reach_from)}} - {{utils.getFormat(reach_to)}} </strong></h2>
          </div>
        </b-col>

        <b-col cols="6" class="mt-3">
          <h4 class="custom-p-modal mt-1 text-center">
            {{ $t('profile.audience_quality') }}
            <b-icon
              font-scale="1"
              icon="question-circle"
              class="icon-tooltip"
              id="estimate_reach_modal"
            ></b-icon>
            <b-tooltip target="estimate_reach_modal" triggers="hover" variant="primary">
              {{$t('profile.estimatedScopeInfo')}}
            </b-tooltip>
          </h4>
          <div class="d-flex">
              <h2 class="ml-2 mb-0">
                <strong>{{$t(`instagram.${data.audience_reachability.title}`)}}</strong>
              </h2>
          </div>
        </b-col>

        <b-col cols="6" class="mt-3">
          <h4 class="custom-p-modal mt-1">
            {{ $t('profile.authenticAudience') }}
            <b-icon
              font-scale="1"
              icon="question-circle"
              class="icon-tooltip"
              id="authentic_audience_modal"
            ></b-icon>
            <b-tooltip target="authentic_audience_modal" triggers="hover" variant="primary">
              {{$t('profile.estimatedScopeInfo')}}
            </b-tooltip>
          </h4>
          <div class="d-flex">
            <h2 class="ml-2 mb-0"><strong>{{$t(`instagram.${data.audience_authenticity.title}`)}}</strong></h2>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" sm="12" md="12" lg="12" xl="4">
      <b-row class="avg-sessions p-1">
        <b-col cols="6" class="mb-2 text-center">
          <h4 class="mb-50"> {{$t("instagram.healthyAudience")}}</h4><h2 class="mb-0 font-weight-bolder">{{influenceLevel}}/100</h2>
          <b-progress class="mt-1" :value="influenceLevel" max="100" height="6px" :variant="getColor(influenceLevel)"></b-progress>
        </b-col>

        <b-col cols="6" class="mb-2 text-center">
          <h4 class="mb-50"> {{$t("instagram.engagementLevel")}}:</h4><h2 class="mb-0 font-weight-bolder">{{utils.getFormat(authEngagement)}} / Post</h2>
          <b-progress class="mt-1" :value="authEngagement" max="100" height="6px" :variant="getColor(authEngagement)"></b-progress>
        </b-col>

        <b-col cols="12" class="mt-3">
          <b-row class="match-height" align-h="center" v-if="brands.length > 0">
            <b-col
              cols="4"
              v-for="(brand, index) in brands"
              :key="index"
            >
              <b-card
                :img-src="brand.avatar"
                class="no-shadow"
              >
                <h4 class="mb-0 font-weight-bolder">
                  {{brand.name}}
                </h4>
              </b-card>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12" v-if="show_brand_safety">
          <b-card class="no-shadow">
            <h2 class="mb-0 font-weight-bolder">{{$t('negativeContent.title')}}
              <b-icon icon="question-circle" class="icon-tooltip" id="titleTooltip-modal"></b-icon>
              <b-tooltip  target="titleTooltip-modal" triggers="hover" variant="primary">
                {{$t(`negativeContent.tooltips.title`)}}
              </b-tooltip>
            </h2>
            <b-row>
              <b-col cols="12" class="my-3">
                <div class="d-flex align-items-center flex-wrap">
                  <div class="d-flex flex-wrap col-12">
                    <div :class="getClassGeneral()"></div>
                    <h4 class="ml-1">
                      <strong>{{getTextGeneral()}}</strong>
                    </h4>
                  </div>
                  <p class="text-muted mt-md-1">{{data.brand_safety.score}} {{$t('negativeContent.of')}} {{lengthItems}} {{$t('negativeContent.patterns')}}</p>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" sm="12" md="12" lg="12"  xl="4" class="mt-3">
      <b-row>
        <b-col cols="12" v-if="countries[0].data.length > 0">
          <b-card class="no-shadow">
            <b-card-header>
              <b-card-title class="subtitle-summary">
                {{$t('instagram.mainCountries')}}
              </b-card-title>
            </b-card-header>
            <b-card-body>
              <vue-apex-charts v-if="showGraph" height="300" type="bar" :options="countryOptions" :series="countries" :key="showGraph"></vue-apex-charts>
            </b-card-body>
          </b-card>
        </b-col>
        
        <b-col cols="12" v-if="percentage_male & percentage_female">
          <b-card no-body class="no-shadow">
            <b-card-header>
              <b-card-title class="subtitle-summary">
                {{$t('instagram.ageRange')}}
              </b-card-title>
            </b-card-header>
            <b-card-body class="pb-0">
              <vue-apex-charts v-if="showGraph" height="300" type="line" :options="chartOptions" :series="ages" :key="showGraph"></vue-apex-charts>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" v-if="!printing && !is_web" class="text-right" align-h="end">
      <b-button
        variant="primary"
        class="hide-sm"
        @click="print()"
      >
        {{$t('printSummary')}}
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BProgress, BCard, BCardHeader, BCardTitle, BCardBody, BButton, BTooltip, BAvatar, BImg } from 'bootstrap-vue'
import utils from '@/libs/utils'
import VueApexCharts from 'vue-apexcharts'
import ImageVerified from '@/views/components/image/ImageVerified.vue';

export default {
  ImageVerified,
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    avatar: {
      type: String,
      default: () => ''
    },
    format: {
      type: String,
      default: 'pdf'
    },
    is_web: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BRow,
    BCol,
    BProgress,
    BCard,
    BCardHeader,
    BCardTitle,
    VueApexCharts,
    BCardBody,
    BButton,
    BTooltip,
    BAvatar,
    BImg
  },
  data () {
    return {
      utils,
      printing: false,
      percentage_male: this.data.demography_by_age.length ? utils.numberFormat(this.data.demography_by_age[0].value) : 0,
      percentage_female: this.data.demography_by_age.length ? utils.numberFormat(this.data.demography_by_age[1].value) : 0,
      reach_from: this.data.est_reach ? this.data.est_reach.from : 0,
      reach_to: this.data.est_reach ? this.data.est_reach.to : 0,
      brands: [],
      type_audience_labels: [],
      type_audience_series: [],
      type_audience_colors: [
        '#40AAEC',
        '#1A66F3',
        '#CF302B',
        '#EC3F79',
        '#F0A23F',
        '#346297',
        '#64D862',
        '#E73631',
        '#924DF7',
        '#F5D90D'
      ],
      type_audience_total: '100%',
      countries: [{name: this.$t('instagram.porcent'),data: this.getSeries(this.data.audience_geography.countries)}],
      ages: [{name: `${this.$t('instagram.male')}`, data: []}, {name: `${this.$t('instagram.female')}`, data: []}],
      chartOptions: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          offsetX: -10,
        },
        stroke: {
          curve: 'smooth',
          dashArray: [0],
          width: [4, 3],
        },
        legend: {
          show: false,
        },
        colors: ['#5cb3ff', '#E75480'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: ['#5cb3ff', '#E75480'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          title: {
            text: `${this.$t('instagram.ageRange')}`,
            offsetY: -10,
            style: {
              fontWeight: 400,
              fontSize: '1rem',
              color: '#b9b9c3'
            }
          },
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
          },
          axisTicks: {
            show: false,
          },
          categories: ['01', '05', '09', '13', '17', '21', '26', '31'],
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
            formatter(val) {
              return `${val}%`
            },
          },
        },
        grid: {
          borderColor: '#e7eef7',
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
      countryOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: '#4be3ac',
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '30%',
            endingShape: 'rounded',
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.getCategories(this.data.audience_geography.countries, 'name'),
          show: true,
          labels: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        }
      },
      showGraph: false,
      show_brand_safety: false,
      print_image: require('@/assets/images/logo/logo_print.png'),
      src_img: ''
    }
  },
  created() {
    const keys_brand_safety = Object.keys(this.data.brand_safety)
    this.show_brand_safety = keys_brand_safety.length > 0
    if (this.data.demography_by_age.length > 0) {
      this.chartOptions.xaxis.categories = this.data.demography_by_age[0].by_age_group.map(item => {
        return item.group.replace('age', '')
      })

      this.data.demography_by_age.forEach((item, index) => {
        this.ages[index].data =  item.by_age_group.map(item => { return item.value })
        this.ages[index].data.pop()
      })
    }
    
    this.chartOptions.xaxis.categories.pop()
    
    const self = this

    Object.keys(this.data.audience_type).forEach(key => {
      this.type_audience_labels.push(self.$t(`profile.${key}`));
    })

    this.type_audience_series = Object.values(this.data.audience_type);

    if (this.data.advertising_data) {
      this.data.advertising_data.brands_mentions.slice(0, 3).forEach(brand => {
        const obj = {
          name: brand.name,
          avatar: ''
        };

        utils.urlImagetoBase64(brand.avatar, function (result) {
          obj.avatar = result
        });
        this.brands.push(obj)
      })
    }

    utils.urlImagetoBase64(this.print_image, function (result) {
      self.src_img = result
    });

    setTimeout(function() {
      self.showGraph = true;
      if (!self.is_web) self.print()
    }, 100);
  },
  computed: {
    aqs() {
      const val = this.data.audience_type['infs'] + this.data.audience_type['real']
      if (val > 0) return Math.round(val)
      return 'No hay datos'
    },
    aqsFormated() {
      const val = this.data.followers_count * this.aqs / 100
      if (this.aqs !== 'No hay datos') return utils.getFormat(val)
      return this.aqs
    },
    followersCount() {
      return utils.getFormat(this.data.followers_count)
    },
    influenceLevel() {
      return Math.round(this.data.audience_reachability.value)
    },
    authEngagement () {
      const audienceAuth = parseFloat(this.data.audience_authenticity.value)
      const followers = parseFloat(this.data.followers_count)
      const er = parseFloat(this.data.er.value)
      const result = (er / 100) * followers * (audienceAuth / 100)
      return Math.round(result)
    },
    lengthItems() {
      if (this.data.brand_safety.length > 0) return Object.keys(this.data.brand_safety.items).length
      else return 0
    }
  },
  methods: {
    getColor (level) {
      if (level === 'Excellent' || level === 'Very Good' || level === 'High') return 'success'
      if (level === 'Good') return 'info'
      if (level === 'Average' || level === 'Poor' || level === 'Low') return 'warning'
      if (level >= 70) return 'success'
      if (level <= 30) return 'danger'
      if (level > 30) return 'warning'
      return 'primary'
    },
    getCategories (data, key) {
      if (!data) return []
      return data.map(item => {
        return item[key]
      })
    },
    getSeries (data) {
      if (!data) return []
      return data.map(item => {
        return item.value
      })
    },
    print() {
      this.printing = true;
      const self = this;
      this.$emit('toogleIconClose', true)

      this.$vs.loading()
      const name_file = `${self.$t('profile.summary')}_${self.data.username}_${self.$route.params.network}_BrandMe`
      setTimeout(function() {
        utils.print('l', `${name_file}`, self.$parent.$parent.$refs.body, function () {
          self.$vs.loading.close()
          self.printing = false;
          self.$emit('toogleIconClose', false)
          self.$emit('closeModal')
        }, self.format)
      }, 500);
    },
    getClassGeneral() {
      let clase = ''

      if (this.data.brand_safety.score === 0) clase = 'aprovado'
      else if (this.data.brand_safety.score >= 1 && this.data.brand_safety.score < 6) clase = 'promedio'
      else clase = 'peligro'

      return `circle-alcance ${clase}`
    },
    getTextGeneral() {
      let text = ''

      if (this.data.brand_safety.score === 0) text = this.$t('negativeContent.sure')
      else if (this.data.brand_safety.score >= 1 && this.data.brand_safety.score < 6) text = this.$t('negativeContent.neutral')
      else text = this.$t('negativeContent.danger')

      return text
    }
  }
}
</script>

<style type="text/css">
  .w-full {
    width: 100%;
  }
  .no-shadow {
    box-shadow: none;
  }
  .color-male {
    color: #5cb3ff;
  }
  .color-female {
    color: #E75480;
  }
  .custom-p-modal {
    color: #626262;
    font-weight: 400;
    font-family: Montserrat,Helvetica,Arial,sans-serif;
  }
</style>
