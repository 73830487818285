<template>
  <b-row>
    <b-col md="10" offset-md="1">
      <div
        class="backgroud-print p-3 pt-5"
        ref="shortPrint"
      >
        <b-img class="float-logo hide-image-sm" :src="require('@/assets/images/pdf/logo.png')"/>
        <b-card class="mt-3 no-shadow">
          <summary-instagram v-if="ready_profile && $route.params.network === 'instagram'" :data="data" :avatar="avatar" :format="format" :is_web="true"/>
          <summary-youtube v-if="ready_profile && $route.params.network === 'youtube'" :data="data" :avatar="avatar" :format="format" :is_web="true"/>
          <summary-tiktok v-if="ready_profile && $route.params.network === 'tiktok'" :data="data" :avatar="avatar" :format="format" :is_web="true"/>
          <summary-twitch v-if="ready_profile && $route.params.network === 'twitch'" :data="data" :avatar="avatar" :format="format" :is_web="true"/>
          <summary-twitter v-if="ready_profile && $route.params.network === 'twitter'" :data="data" :avatar="avatar" :format="format" :is_web="true"/>
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BCard, BImg } from 'bootstrap-vue'
import summaryInstagram from './summaryInstagram.vue'
import summaryYoutube from './summaryYoutube.vue'
import summaryTiktok from './summaryTiktok.vue'
import summaryTwitch from './summaryTwitch.vue'
import summaryTwitter from './summaryTwitter.vue'
import service from '@/services/profile'
import utils from '@/libs/utils'

export default {
  props: {
    format: {
      type: String,
      default: 'pdf'
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    summaryInstagram,
    summaryYoutube,
    summaryTiktok,
    summaryTwitch,
    summaryTwitter
  },
  data () {
    return {
      params: {
        username: this.$route.params.alias,
        network: this.$route.params.network
      },
      data: {},
      ready_profile: false,
      avatar: ''
    }
  },
  created() {
    this.$vs.loading()
    this.getSpotlight();
  },
  methods: {
    getSpotlight () {
      service.getChild(this.params)
        .then(response => {
          this.data = response.main_child.detail

          if (response.file_for_profile === null) {
            this.avatar = response.image_url;
          } else {
            this.avatar = utils.getAssetUrl(response.file_for_profile)
          }
          console.log(this.avatar)
          this.ready_profile = true;
          this.$vs.loading.close()
        })
        .catch(() => {
          const self = this
          setTimeout(function() {
            self.$vs.loading.close()
          }, 500);
        })

    },
  }
}
</script>

<style type="text/css">
  .print-logo {
    width: 100px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
  }
  .backgroud-print {
    background: url('~@/assets/images/pdf/background.png') no-repeat center #eee;
    background-size: cover;
  }
  .logo-twitter {
    top: -4% !important;
  }
  .float-logo {
    position: absolute;
    right: 14px;
    top: 0;
    width: 250px;
    z-index: 5000;
  }
  .w-third {
    width: 100%;
    height: auto;
    max-width: 334px;
  }
  .font-30 {
    font-size: 30px;
  }
  .margin-icon{
    z-index: 1;
    border-radius: 100%;
    padding: 6px;
    background: white;
    z-index: 2000 !important;
  }
  .mt-10 {
    margin-top: 14rem !important;
  }
  .float-image {
    position: absolute;
    top: -85px;
    right: 0;
  }
  .float-image img {
    z-index: 1000;
  }
  .float-image .b-avatar-badge {
    z-index: 2000 !important;
  }
  .summary-title {
    color: #0C23E2;
    font-size: 3rem;
  }
  .subtitle-summary {
    color: #0C23E2;
    font-size: 2.2rem !important;
    font-weight: 500;
  }
  .icon-close {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #FDFFFF;
    cursor: pointer;
  }
  .icon-close:hover {
    background-color: rgb(248, 255, 255, 0.5);
    border-radius: 50%;
    padding: 2px;
  }
  .w-full-modal {
    max-width: 100%;
  }

  @media(min-width:1075px) and (max-width: 1999px){
    .mt-10 {
      margin-top: 19rem !important;
    }
  }
  @media(min-width: 2259px){
    .mt-10 {
      margin-top: 17rem !important;
    }
  }

  @media(min-width: 2595px){
    .mt-10 {
      margin-top: 19rem !important;
    }
  }

  @media(min-width: 2958px){
    .mt-10 {
      margin-top: 23rem !important;
    }
  }

  @media(min-width: 3336px){
    .mt-10 {
      margin-top: 27rem !important;
    }
  }

  @media(min-width: 3800px){
    .mt-10 {
      margin-top: 30rem !important;
    }
  }

  @media (max-width: 576px) {
    .hide-sm {
      display: none !important;
    }
  }

  @media (max-width: 759px) {
    .hide-image-sm {
      display: none !important;
    }
    .show-image-sm {
      display: flex !important;
      align-content: center;
      position: absolute;
      top: 125px;
      width: 120px;
    }
  }

  @media (min-width: 760px) {
    .show-image-sm {
      display: none !important;
    }
  }

</style>